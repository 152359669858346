import { Button, Stack, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import ScrollBooster from 'scrollbooster';
import useTranslation from 'app/hooks/useTranslation';
import { ScrollbarContainer, ScrollbarThumb, ScrollbarTrack } from './elements';
var mobileButtons = [
    {
        label: 'menuButtons.tickets',
        link: '',
        iconSrc: require('../../assets/icons/Airport.svg'),
    },
    {
        label: 'menuButtons.hotels',
        link: 'https://www.booking.com/?aid=330436',
        iconSrc: require('../../assets/icons/Hotels.svg'),
    },
    {
        label: 'footer.transfer',
        link: 'https://volivolo.gettransfer.com',
        iconSrc: require('../../assets/icons/Transfers.svg'),
    },
    // {
    //   label: 'Asigurari',
    //   link: 'https://www.asigurari.ro',
    //   iconSrc: require('../../assets/icons/Insurance.svg'),
    // },
    // {
    //   label: 'Charter',
    //   link: '',
    //   iconSrc: require('../../assets/icons/Charter.svg'),
    // },
];
var MobileNavigation = function () {
    var t = useTranslation();
    var scrollBooster = useRef(null);
    var scrollableContainerRef = useRef(null);
    var boxRef = useRef(null);
    var _a = useState(0), scrollbarPosition = _a[0], setScrollbarPosition = _a[1];
    useEffect(function () {
        if (scrollableContainerRef.current && boxRef.current) {
            if (scrollBooster.current) {
                scrollBooster.current.updateMetrics();
            }
            else {
                scrollBooster.current = new ScrollBooster({
                    viewport: scrollableContainerRef.current,
                    content: boxRef.current,
                    direction: 'horizontal',
                    scrollMode: 'transform',
                    onUpdate: function (state) {
                        var offset = (state.position.x - 5) / 2;
                        setScrollbarPosition(offset < 0 ? 0 : offset > 358 ? 358 : offset);
                    },
                });
            }
        }
    }, []);
    var handleTransferClick = function (link) {
        if (link) {
            window.open("" + link, '_blank');
        }
    };
    return (React.createElement(Stack, { ref: scrollableContainerRef, pt: 1, sx: {
            width: '100%',
            overflow: 'hidden',
        } },
        React.createElement(Stack, { ref: boxRef, direction: "row" },
            React.createElement(Stack, { flex: { flex: '1 0 auto' }, direction: "row", sx: {
                    padding: 0,
                    gap: '24px',
                } }, mobileButtons.map(function (_a, i) {
                var label = _a.label, link = _a.link, iconSrc = _a.iconSrc;
                return (React.createElement(Button, { variant: "contained", onClick: function () { return handleTransferClick(link); }, sx: {
                        height: '32px',
                        width: 'fit-content',
                        padding: i === 0 ? '0 12px' : '0',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        backgroundColor: i === 0 ? '#C476FF' : 'transparent',
                        fontSize: '14px',
                        borderRadius: '8px',
                        gap: 1,
                        boxShadow: 'none',
                        '&:hover': {
                            backgroundColor: 'rgba(21, 162, 185, 0.3)',
                            boxShadow: 'none',
                        },
                    }, key: i },
                    React.createElement("img", { src: iconSrc, alt: label, style: { width: '1.25em', height: '1.25em' } }),
                    React.createElement(Typography, null, t(label))));
            }))),
        React.createElement(ScrollbarContainer, null,
            React.createElement(ScrollbarTrack, null),
            React.createElement(ScrollbarThumb, { style: { transform: "translate(" + scrollbarPosition + "px, 0px)" } }))));
};
export default MobileNavigation;
