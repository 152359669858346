import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ConctactLink, IconSCTwo } from './styles';
import Logo from 'app/components/Menu/newLogo.svg';
import Icon2Ico from './icons/socials/Icon2.svg';
// import Icon3Ico from './icons/socials/Icon3.svg';
import Icon5Ico from './icons/socials/Icon5.svg';
import useTranslation, { useLocaleId } from '../../hooks/useTranslation';
import { Box } from '@material-ui/system';
import { Container, Link as MuiLink } from '@mui/material';
import Multibanner from './assets/multibanner.svg';
// import whatsapp from './icons/WhatsApp.svg';
// import viber from './icons/Viber.svg';
// import skype from './icons/Skype.svg';
import { configSiteName, configContactEmail, configFacebookUrl, configInstagramUrl, configLegalName } from '../../util/siteConfig';
// import ArrowUpward from 'app/assets/icons/ArrowUpward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AnpcImageIt from './assets/anpc_sal_it.png';
import EuSolImageIt from './assets/eu_sol_it.png';
import AnpcImageEn from './assets/anpc_sal_en.png';
import EuSolImageEn from './assets/eu_sol_en.png';
var links = [
    {
        title: configSiteName,
        items: [
            {
                title: 'footer.about',
                link: '/about',
            },
            {
                title: 'footer.terms',
                link: '/terms-and-conditions',
            },
            {
                title: 'footer.contacts',
                link: '/contacts',
            },
            {
                title: 'footer.privacy',
                link: '/privacy',
            },
            {
                title: 'footer.iata',
                link: '/iata-terms-and-conditions',
            },
            {
                title: 'footer.cookies',
                link: '/cookie-policy',
            },
        ],
    },
    {
        title: 'footer.more',
        items: [
            {
                title: 'menuButtons.bonus',
                link: '/bonus-program',
            },
            {
                title: 'footer.faq',
                link: '/domande-frequenti',
            },
            {
                title: 'footer.specialNeeds',
                link: '/special-needs-rules',
            },
            {
                title: 'footer.whatsCheckin',
                link: '/check-in',
            },
            // {
            //   title: 'footer.checkin',
            //   link: '/check-in-online',
            // },
            {
                title: 'footer.blog',
                link: '/blog',
            },
        ],
    },
    {
        title: 'footer.services',
        items: [
            {
                title: 'footer.tickets',
                link: '/',
            },
            {
                type: 'external',
                title: 'footer.hotels',
                link: 'https://www.booking.com/?aid=330436',
            },
            {
                type: 'external',
                title: 'footer.transfer',
                link: 'https://volivolo.gettransfer.com',
            },
            // {
            //   type: 'external',
            //   title: 'footer.insurances',
            //   link: 'https://www.asigurari.ro/',
            // },
            {
                title: 'footer.groupOffer',
                link: '/group-offer',
            },
            {
                title: 'footer.corporate',
                link: '/corporate',
            },
            {
                title: 'businessClass.heroSection.title',
                link: '/business-class',
            },
        ],
    },
];
var FooterSection = function () {
    var t = useTranslation();
    var _a = useState(false), showButton = _a[0], setShowButton = _a[1];
    var locale = useLocaleId();
    useEffect(function () {
        var handleScrollButtonVisibility = function () {
            window.pageYOffset > 300 ? setShowButton(true) : setShowButton(false);
        };
        window.addEventListener('scroll', handleScrollButtonVisibility);
        return function () {
            window.removeEventListener('scroll', handleScrollButtonVisibility);
        };
    }, []);
    var handleSrollTop = function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { sx: {
                backgroundColor: 'primary.dark',
                borderRadius: {
                    xs: '24px 24px 0 0 ',
                    md: '48px 48px 0px 0px',
                },
                padding: {
                    xs: '32px 16px 24px',
                    md: '64px 0 48px 0',
                },
                margin: '50px 0 0 0',
                color: '#fff',
                zIndex: '2',
            } },
            React.createElement(Container, { maxWidth: "xl", sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    padding: '0 0 32px 0',
                } },
                React.createElement(Box, { sx: {
                        width: { xs: '100%', md: 'auto' },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    } },
                    React.createElement(Box, { sx: {
                            width: '100%',
                            display: 'flex',
                            alignItems: { xs: 'center', md: 'flex-start' },
                            justifyContent: 'space-between',
                            flexDirection: { xs: 'row', md: 'column' },
                            marginBottom: '48px',
                        } },
                        React.createElement(Box, { component: 'img', src: Logo, alt: configSiteName, sx: {
                                width: { xs: '130px', md: '200px' },
                                margin: { xs: '0', md: '0 0 48px 0' },
                            } }),
                        React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } },
                            React.createElement(Box, null,
                                React.createElement(ConctactLink, { href: "mailto:" + configContactEmail }, configContactEmail)))),
                    React.createElement(Box, { sx: {
                            display: 'flex',
                            gap: '8px',
                            margin: {
                                xs: '0 0 48px 0',
                                md: '0',
                            },
                        } },
                        React.createElement("a", { href: configFacebookUrl, target: "_blank", rel: "noreferrer" },
                            React.createElement(IconSCTwo, { src: Icon2Ico, alt: "Facebook" })),
                        React.createElement("a", { href: configInstagramUrl, target: "_blank", rel: "noreferrer" },
                            React.createElement(IconSCTwo, { src: Icon5Ico, alt: "Instagram" })))),
                React.createElement(Box, { sx: {
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: {
                            xs: '48px 16px',
                            md: '30px',
                        },
                    } }, links.map(function (col, key) { return (React.createElement(Box, { sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        width: {
                            xs: 'calc(50% - 8px)',
                            md: '165px',
                        },
                        '&:last-of-type': {
                            width: { xs: '100%', md: '165px' },
                        },
                    }, key: key },
                    React.createElement(Box, { sx: {
                            fontWeight: 700,
                            fontSize: '18px',
                            lineHeight: '120%',
                            margin: {
                                xs: '0 0 8px 0',
                                md: '0 0 4px 0',
                            },
                        } }, t(col.title)),
                    col.items.map(function (item, key) {
                        return item.type === 'external' ? (React.createElement("a", { href: item.link, target: "_blank", rel: "noreferrer", style: {
                                display: 'inline-block',
                                fontSize: '14px',
                                lineHeight: '140%',
                                color: '#fff',
                                textDecoration: 'none',
                                padding: '4px 0',
                            }, key: key }, t(item.title))) : (React.createElement(Link, { to: "/" + locale + item.link, style: {
                                display: 'inline-block',
                                fontSize: '14px',
                                lineHeight: '140%',
                                color: '#fff',
                                textDecoration: 'none',
                                padding: '4px 0',
                            }, key: key }, t(item.title)));
                    }))); }))),
            React.createElement(Container, { maxWidth: "xl", sx: {
                    position: 'relative',
                    padding: {
                        xs: '16px 0 0',
                        md: '24px 16px 0',
                    },
                    '&:before': {
                        content: '""',
                        position: 'absolute',
                        top: '0',
                        left: {
                            xs: '0',
                            md: '24px',
                        },
                        width: {
                            xs: '100%',
                            md: 'calc(100% - 48px)',
                        },
                        height: '1px',
                        borderTop: '1px solid  rgba(255, 255, 255, 0.5)',
                    },
                } },
                React.createElement(Box, { sx: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        margin: '0 0 32px 0',
                    } },
                    React.createElement(Box, { sx: {
                            display: 'flex',
                            gap: '8px',
                            margin: {
                                xs: '0 0 16px 0',
                                md: '0',
                            },
                            width: {
                                xs: '100%',
                                md: '41%',
                            },
                        } },
                        React.createElement("a", { href: "https://anpc.ro/ce-este-sal/", target: "_blank", rel: "noreferrer", style: { width: '57%' } },
                            React.createElement("img", { src: locale === 'it' ? AnpcImageIt : AnpcImageEn, alt: "ANPC", style: { width: '100%' } })),
                        React.createElement("a", { href: "https://ec.europa.eu/consumers/odr", target: "_blank", rel: "noreferrer", style: { width: '43%' } },
                            React.createElement("img", { src: locale === 'it' ? EuSolImageIt : EuSolImageEn, alt: "Eu consumers", style: { maxHeight: '60px' } }))),
                    React.createElement(Box, { sx: {
                            width: {
                                xs: '100%',
                                md: '48%',
                            },
                        } },
                        React.createElement("img", { src: Multibanner, alt: "Metode de plata", style: { width: '100%' } }))),
                React.createElement(Box, { sx: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: {
                            xs: '24px 0',
                            md: '0',
                        },
                        flexWrap: 'wrap',
                        fontSize: '12px',
                        lineHeight: '140%',
                        opacity: '0.7',
                        margin: '0 0 32px 0',
                    } },
                    React.createElement(Box, { sx: {
                            width: {
                                xs: '100%',
                                md: 'calc(50% - 16px)',
                            },
                        } }, t('legal.address')),
                    React.createElement(Box, { sx: {
                            width: {
                                xs: '100%',
                                md: 'calc(50% - 16px)',
                            },
                        } }, t('company.details')),
                    React.createElement(Box, { sx: {
                            width: {
                                xs: '100%',
                                md: 'calc(50% - 16px)',
                            },
                        } },
                        React.createElement(MuiLink, { href: "/LicentaTurism_Anexa.pdf", sx: { color: '#fff', textDecorationColor: '#fff' }, target: "_blank" },
                            t('tourism_license'),
                            ": 3/22.10.2018"))),
                React.createElement(Box, { sx: {
                        fontSize: '14px',
                        lineHeight: '140%',
                    } }, t('footer.copyright', { configLegalName: configLegalName })))),
        React.createElement(Box, { sx: {
                position: 'fixed',
                bottom: '80px',
                right: '10px',
                backgroundColor: 'primary.dark',
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
                justifyContent: 'center',
                width: '48px',
                height: '48px',
                borderRadius: '50%',
                boxShadow: '0px 8px 32px rgba(255, 255, 255, 0.3)',
                zIndex: '2',
                cursor: 'pointer',
                opacity: showButton ? '1' : '0',
                visibility: showButton ? 'visible' : 'hidden',
                transition: '0.2s',
                '& svg': {
                    fill: '#fff',
                },
            }, onClick: handleSrollTop },
            React.createElement(ArrowUpwardIcon, null))));
};
export default FooterSection;
