import React, { useMemo, useCallback, useLayoutEffect, useRef } from 'react';
import { startOfDay, isBefore, startOfMonth, endOfMonth, isMonday, addDays, getDaysInMonth, setDate, addMonths, isWithinInterval, format as fnsFormat, } from 'date-fns';
import { WeekDayNamesContainer, WeekDayName, MonthTable, WeekRow, MonthAndYear, MonthNavigationWrapper, } from './elements';
import MonthDay from './MonthDay';
import { useDateFormat, useLocaleId, } from '../../../../hooks/useTranslation';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { capitalize, Hidden, Stack, Box, useMediaQuery, } from '@material-ui/core';
var rangeOfSeven = [0, 1, 2, 3, 4, 5, 6];
var weekDayLabels = {
    it: ['Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa', 'Do'],
    en: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
};
var MonthView = function (props) {
    var initialDate = props.initialDate, _a = props.navigationDirection, navigationDirection = _a === void 0 ? 'both' : _a, onPrevMonth = props.onPrevMonth, isMonthOfSelectedValue = props.isMonthOfSelectedValue, onNextMonth = props.onNextMonth, minDate = props.minDate, maxDate = props.maxDate, onDateHover = props.onDateHover, hoveredDate = props.hoveredDate, _b = props.daysProperties, daysProperties = _b === void 0 ? {} : _b, setPickerMode = props.setPickerMode, setPotentialValues = props.setPotentialValues, syncWithValue = props.syncWithValue;
    var startOfMonthDate = useMemo(function () { return startOfMonth(initialDate || new Date()); }, [initialDate]);
    var endOfMonthDate = useMemo(function () { return endOfMonth(initialDate || new Date()); }, [initialDate]);
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var rootRef = useRef(null);
    var locale = useLocaleId();
    useLayoutEffect(function () {
        if (isMonthOfSelectedValue) {
            rootRef.current && rootRef.current.scrollIntoView();
        }
    }, [isMonthOfSelectedValue]);
    var prevMonthDate = useMemo(function () { return addDays(startOfMonthDate, -1); }, [startOfMonthDate]);
    var nextMonthDate = useMemo(function () { return addMonths(startOfMonthDate, 1); }, [startOfMonthDate]);
    var canGoPrevMonth = useMemo(function () {
        return (navigationDirection === 'backward' || navigationDirection === 'both') &&
            (minDate ? prevMonthDate >= minDate : true);
    }, [minDate, navigationDirection, prevMonthDate]);
    var canGoNextMonth = useMemo(function () {
        return (navigationDirection === 'forward' || navigationDirection === 'both') &&
            (maxDate ? nextMonthDate <= maxDate : true);
    }, [maxDate, navigationDirection, nextMonthDate]);
    var days = useMemo(function () {
        var days = [];
        if (!isMonday(startOfMonthDate)) {
            for (var i = 1; i < (startOfMonthDate.getDay() || 7); i++) {
                days.push({
                    date: addDays(startOfMonthDate, i - (startOfMonthDate.getDay() || 7)),
                    isInMonthBounds: false,
                });
            }
        }
        for (var i = 1; i <= getDaysInMonth(startOfMonthDate); i++) {
            days.push({
                date: setDate(startOfMonthDate, i),
                isInMonthBounds: true,
            });
        }
        var daysCount = days.length;
        var beginningOfNextMonthDate = addMonths(startOfMonthDate, 1);
        for (var i = 0; i < 42 - daysCount; i++) {
            days.push({
                date: addDays(beginningOfNextMonthDate, i),
                isInMonthBounds: false,
            });
        }
        return days;
    }, [startOfMonthDate]);
    var handleChange = useCallback(function (date) {
        var _a;
        if (props.onChange) {
            if (props.isRange) {
                if (((_a = props.value) === null || _a === void 0 ? void 0 : _a[0]) && !props.value[1]) {
                    props.onChange([props.value[0], date].sort(function (a, b) { return a.valueOf() - b.valueOf(); }));
                }
                else {
                    props.onChange([date, null]);
                }
            }
            else {
                props.onChange(date);
            }
        }
    }, [props]);
    var isSelected = useCallback(function (date) {
        var _a, _b;
        if (props.isRange) {
            return (_a = props.value) === null || _a === void 0 ? void 0 : _a.map(function (date) { return date === null || date === void 0 ? void 0 : date.valueOf(); }).includes(date.valueOf());
        }
        return ((_b = props.value) === null || _b === void 0 ? void 0 : _b.valueOf()) === date.valueOf();
    }, [props.isRange, props.value]);
    var format = useDateFormat();
    var isInRange = useCallback(function (date) {
        if (props.isRange && props.value) {
            if (props.value[0]) {
                if (props.value[1]) {
                    return date > props.value[0] && date < props.value[1];
                }
                if (hoveredDate) {
                    var sortedDates = [props.value[0], hoveredDate].sort(function (a, b) { return a.valueOf() - b.valueOf(); });
                    return isWithinInterval(date, {
                        start: sortedDates[0],
                        end: sortedDates[1],
                    });
                    // return date > props.value[0] && date <= hoveredDate;
                }
            }
        }
        return false;
    }, [hoveredDate, props.isRange, props.value]);
    var selectWholeMonth = useCallback(function () {
        setPickerMode('range');
        setPotentialValues([
            isBefore(startOfMonthDate, new Date())
                ? startOfDay(new Date())
                : startOfMonthDate,
            endOfMonthDate,
        ]);
        syncWithValue();
    }, [
        setPickerMode,
        startOfMonthDate,
        endOfMonthDate,
        setPotentialValues,
        syncWithValue,
    ]);
    return (React.createElement(MonthTable, { ref: rootRef },
        React.createElement(MonthAndYear, null,
            canGoPrevMonth && (React.createElement(MonthNavigationWrapper, { isPrev: true, tabIndex: 0, onClick: onPrevMonth },
                React.createElement(Hidden, { smDown: true },
                    React.createElement(ChevronLeft, null)))),
            React.createElement(Box, { onClick: selectWholeMonth }, capitalize(format(startOfMonthDate, 'LLLL'))),
            canGoNextMonth && (React.createElement(MonthNavigationWrapper, { tabIndex: 0, onClick: onNextMonth },
                React.createElement(Hidden, { smDown: true },
                    React.createElement(ChevronRight, null))))),
        React.createElement(Hidden, { smDown: true },
            React.createElement(WeekDayNamesContainer, null, rangeOfSeven.map(function (dayNumber) { return (React.createElement(WeekDayName, { key: dayNumber }, weekDayLabels[locale][dayNumber])); }))),
        React.createElement(Stack, { sx: isMobile ? {} : { padding: '0 0 16px 22px' }, className: "123" }, Array(6)
            .fill(0)
            .map(function (_, weekIndex) {
            return days[weekIndex * 7].date > startOfMonthDate &&
                !days[weekIndex * 7].isInMonthBounds ? null : (React.createElement(WeekRow, { key: weekIndex }, Array(7)
                .fill(0)
                .map(function (_, dayIndex) {
                var dayConfig = days[weekIndex * 7 + dayIndex];
                if (!dayConfig) {
                    return null;
                }
                return (React.createElement(MonthDay, { key: weekIndex + "-" + dayIndex, date: dayConfig.date, rangeProperties: daysProperties[fnsFormat(dayConfig.date, 'yyyy-MM-dd')], disabled: (minDate && dayConfig.date < minDate) ||
                        (maxDate && dayConfig.date > maxDate), 
                    // hidden={maxDate && dayConfig.date > maxDate}
                    selected: isSelected(dayConfig.date), highlighted: isInRange(dayConfig.date), onClick: handleChange, onMouseEnter: onDateHover, isInMonthBounds: dayConfig.isInMonthBounds }));
            })));
        }))));
};
export default MonthView;
