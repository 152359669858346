var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { PristineButton } from '../../buttons';
export var RangePickerRoot = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: inline-block;\n  ", " {\n    display: block;\n    width: 100%;\n    margin-bottom: 10px;\n  }\n"], ["\n  position: relative;\n  display: inline-block;\n  ", " {\n    display: block;\n    width: 100%;\n    margin-bottom: 10px;\n  }\n"])), function (p) { return p.theme.breakpoints.down('sm'); });
export var RangePickerAbsoluteContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  z-index: 3;\n  position: absolute;\n  top: calc(3em + 5px);\n  left: ", ";\n\n  transform: translateX(\n    ", "\n  );\n  background: #ffffff;\n  border: 1px solid #c476ff;\n  filter: drop-shadow(0px 4px 10px rgba(1, 21, 52, 0.16));\n  border-radius: 4px;\n  box-shadow: #1e1f28 0px 2px 20px;\n  ", " {\n    top: 70px;\n  }\n  ", " {\n    position: fixed;\n    left: 50%;\n    top: 105px;\n    transform: translateX(-50%);\n  }\n"], ["\n  z-index: 3;\n  position: absolute;\n  top: calc(3em + 5px);\n  left: ", ";\n\n  transform: translateX(\n    ", "\n  );\n  background: #ffffff;\n  border: 1px solid #c476ff;\n  filter: drop-shadow(0px 4px 10px rgba(1, 21, 52, 0.16));\n  border-radius: 4px;\n  box-shadow: #1e1f28 0px 2px 20px;\n  ", " {\n    top: 70px;\n  }\n  ", " {\n    position: fixed;\n    left: 50%;\n    top: 105px;\n    transform: translateX(-50%);\n  }\n"])), function (props) { return (props.secondary ? '-9px' : null); }, function (p) {
    switch (p.align) {
        case 'left':
            return '0';
        case 'right':
            return '-76%';
        case 'center':
            return '-33%';
    }
}, function (p) { return p.theme.breakpoints.down('md'); }, function (p) { return p.theme.breakpoints.down('sm'); });
export var RangePickerCalendars = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  ", " {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  ", " {\n    flex-direction: column;\n  }\n"])), function (p) { return p.theme.breakpoints.down('sm'); });
export var RangePickerTimeInputs = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  padding-bottom: 6px;\n"], ["\n  display: flex;\n  padding-bottom: 6px;\n"])));
export var RangePickerTimeInput = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  padding: 6px;\n  padding-bottom: 0;\n  > input {\n    background-color: #191a21;\n\n    &::-webkit-calendar-picker-indicator {\n      filter: invert(1);\n      cursor: pointer;\n    }\n  }\n\n  & + & {\n    border-left: 1px solid #33373f;\n  }\n"], ["\n  display: flex;\n  flex: 1;\n  padding: 6px;\n  padding-bottom: 0;\n  > input {\n    background-color: #191a21;\n\n    &::-webkit-calendar-picker-indicator {\n      filter: invert(1);\n      cursor: pointer;\n    }\n  }\n\n  & + & {\n    border-left: 1px solid #33373f;\n  }\n"])));
export var RangePickerPopularOptions = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
export var RangePickerPopularOption = styled(PristineButton)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  font-size: 1em;\n  color: #dfdfdf;\n  cursor: pointer;\n  padding: 10px 12px;\n"], ["\n  display: flex;\n  font-size: 1em;\n  color: #dfdfdf;\n  cursor: pointer;\n  padding: 10px 12px;\n"])));
export var CloseOverlay = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 1;\n  ", " {\n    background: rgba(0, 0, 0, 0.5);\n  }\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 1;\n  ", " {\n    background: rgba(0, 0, 0, 0.5);\n  }\n"])), function (p) { return p.theme.breakpoints.down('sm'); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
