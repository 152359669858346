import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import FlightSearchForm from 'app/components/FlightSearchForm/SearchForm/FlightSearchForm';
import WelcomeSection from 'app/components/WelcomeSection/WelcomeSection';
import DesktopMenu from 'app/components/Menu/DesktopMenu';
import MobileMenu from 'app/components/Menu/MobileMenu';
import { Box, Container, Typography, useMediaQuery, } from '@material-ui/core';
import Layout from 'app/components/Layout';
import SpecialOffers from 'app/components/SpecialOffers/SpecialOffers';
import TravelAndSave from 'app/components/Home/TravelAndSave';
import SubscribeForm from 'app/components/SubscribeForm/SubscribeForm';
import OurAdvantages from 'app/components/Home/OurAdvantages';
import { StringParam, useQueryParams } from 'use-query-params';
import useTranslation from 'app/hooks/useTranslation';
import { AnalyticsContext } from 'app/hooks/useAnalytics';
import heroSectionBg from '../../public/assets/home/hero-section-bg.png';
import { configSiteName } from 'app/util/siteConfig';
function Home() {
    var t = useTranslation();
    var isMobile = useMediaQuery(function (theme) { return theme.breakpoints.down('sm'); });
    var _a = useQueryParams({
        utm_source: StringParam,
        utm_medium: StringParam,
        utm_campaign: StringParam,
        utm_term: StringParam,
    }), queryParams = _a[0], setQueryParams = _a[1];
    var utmContext = useContext(AnalyticsContext);
    useEffect(function () {
        utmContext.setAnalyticsContext({
            ga: utmContext.utmData.ga,
            utmCampaign: queryParams.utm_campaign || undefined,
            utmMedium: queryParams.utm_medium || undefined,
            utmTerm: queryParams.utm_term || undefined,
            utmSource: queryParams.utm_source || undefined,
        });
    }, [queryParams]);
    var Header = function () {
        return (React.createElement(React.Fragment, null,
            !isMobile && (React.createElement(Box, { sx: {
                    position: 'sticky',
                    top: '0',
                    backgroundColor: 'primary.dark',
                    zIndex: '3',
                } },
                React.createElement(Container, { disableGutters: !isMobile },
                    React.createElement(DesktopMenu, { paletteMode: "dark", navigationType: "buttons" })))),
            React.createElement(Box, { sx: {
                    position: 'relative',
                    backgroundImage: { xs: 'none', md: "url(" + heroSectionBg + ")" },
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundColor: 'primary.dark',
                } },
                React.createElement(Box, { sx: {
                        width: '100%',
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: { xs: '0 0 50px 0', md: '80px 0 160px 0' },
                    } },
                    isMobile && (React.createElement(Box, { sx: { width: '100%', padding: '0 16px' } },
                        React.createElement(MobileMenu, { navVisible: true }))),
                    React.createElement(Typography, { variant: "h3", sx: {
                            maxWidth: '475px',
                            fontSize: { xs: '22px', md: '34px' },
                            lineHeight: '1.4',
                            color: '#fff',
                            textAlign: 'center',
                            margin: { xs: '24px 0 16px', md: '0 0 24px 0' },
                            padding: '0 16px',
                        } }, isMobile
                        ? t('cheap.plane.tickets.online')
                        : t('compare.prices.and.find.cheap.plane.tickets.online')),
                    React.createElement(Container, { sx: {
                            width: '100%',
                            margin: '16px 0 0 0',
                        } },
                        React.createElement(FlightSearchForm, null))))));
    };
    return (React.createElement(Layout, { HeaderComponent: React.createElement(Header, null) },
        React.createElement(Helmet, null,
            React.createElement("title", null, t('meta.home.title', { configSiteName: configSiteName })),
            React.createElement("meta", { name: "description", content: t('meta.home.desc', { configSiteName: configSiteName }) })),
        React.createElement(Container, null,
            React.createElement(SpecialOffers, null),
            React.createElement(TravelAndSave, null),
            React.createElement(WelcomeSection, null),
            React.createElement(OurAdvantages, null)),
        React.createElement(SubscribeForm, null)));
}
export default Home;
